import {LoginComponent} from './login.component';
import {RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
import {TextFieldModule} from '../../shared/custom-components/text-field/text-field.module';
import {ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {CommonModule} from '@angular/common';
import {ButtonModule} from '../../shared/custom-components/button/button.module';
import {HideNavigationBar} from '../navigation-bar/navigation-bar-options';

export const routes = [
	{
		path: '',
		title: 'pageTitle.login',
		component: LoginComponent,
		data: {navigationBarOptions: HideNavigationBar}
	}
];

@NgModule({
	declarations: [
		LoginComponent
	],
	imports: [
		RouterModule.forChild(routes),
		TextFieldModule,
		ReactiveFormsModule,
		ButtonModule,
		TranslateModule,
		CommonModule,
	]
})

export class LoginModule {
}
