<div class="nav-bar-wrapper">
	<img id="navigation-bar-not-logged-in"
	     class="clinic-logo"
	     src="assets/logo/hairclinic_logo.png"
	     alt="clinic logo">
</div>
<div class="other-space">
	<div class="login-wrapper">
		<form [formGroup]="loginForm" (ngSubmit)="login()">
			<h1 class="login-text" [translate]="'logIn.title'"></h1>
			<aga-text-field class="text-field"
			                id="email"
			                formControlName="email"
			                [label]="'form.email' | translate">
			</aga-text-field>
			<aga-text-field class="text-field"
			                id="password"
			                type="password"
			                formControlName="password"
			                [label]="'form.password' | translate">
			</aga-text-field>
			<h2><a routerLink="/forgot-password" [translate]="'logIn.forgotPassword'"></a></h2>
			<aga-button class="float-end"
			            id="login"
			            type="submit"
			            [disabled]="loginForm.invalid || loading">{{'logIn.logIn' | translate | uppercase}}
			</aga-button>
		</form>
	</div>
</div>


