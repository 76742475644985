import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {UserAccountManagementService} from '../../core/user-account-management/user-account-management.service';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {SubscriptionUtil} from '../../../utils/subscriptions.util';
import {ApplicationError} from '../../core/errors/models/application-error.model';
import {User} from '../../core/models/user.model';
import {ClinicsService} from '../../shared/services/clinics/clinics.service';
import {AuthenticationService} from '../../core/authentication/authentication.service';

@Component({
	selector: 'aga-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
	loginForm: UntypedFormGroup;
	loading: boolean;
	subscription: Subscription;

	constructor(private authService: AuthenticationService,
	            private userService: UserAccountManagementService,
	            private clinicsService: ClinicsService,
	            private router: Router) {
	}

	ngOnInit(): void {
		this.initForm();
	}

	initForm(): void {
		this.loginForm = new UntypedFormGroup({
			email: new UntypedFormControl('', [Validators.required, Validators.email]),
			password: new UntypedFormControl('', [Validators.required, Validators.minLength(8)])
		});
	}

	login(): void {
		this.loading = true;
		this.subscription = this.authService.login(this.loginForm.value.email, this.loginForm.value.password).subscribe(
			(user: User) => {
				this.userService.currentUser = user;
				this.router.navigate(['/appointments-calendar-preview']);
			},
			(error: ApplicationError) => {
				this.loading = false;
				error.showError();
			}
		);
	}

	ngOnDestroy(): void {
		SubscriptionUtil.unsubscribe(this.subscription);
	}
}
